import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { plainToInstance } from 'class-transformer';

import { environment } from '../../../../environments/environment';
import { User } from '../../../auth/models/user.model';
import { LoginService } from '../../../auth/services/login.service';
import { Company } from '../../../models/company.model';
import { CompanyService } from '../../../services/company.service';
import { ComponentCommService } from '../../../services/component-comm.service';
import { PusherService } from '../../../services/pusher.service';
import { checkAccept } from '../../../utilities/file';
import { SubscriptionManager } from '../../../utilities/subscription-manager';
import { ChatMessage } from '../../models/chat-message.model';
import { ChatRoom } from '../../models/chat-room.model';
import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'ag-center',
  templateUrl: './center.component.html',
  styleUrls: ['./center.component.scss']
})
export class CenterComponent extends SubscriptionManager implements OnInit, OnDestroy {

  @ViewChild('dropArea', { static: true }) private readonly dropArea: ElementRef;
  @ViewChild('dropLegend') private readonly dropLegend: ElementRef;
  @ViewChild('scrollMe') private readonly scrollMe: ElementRef;

  /**
   * Specify what file types the user can attach to a chat.
   * 
   * Works as
   * [[https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept|HTML attribute: accept]].
   */
  public accept: string = "";
  public chatHeight: number = 0;
  public company: Company;
  public enableAttachments: boolean = true;
  public logosOverlap: number = 20; // in pixels
  public newMessage: { [roomId: string]: string } = {}; // Input ngModel
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public rooms: ChatRoom[];
  public selectedIndex: number;
  public unread: { [roomId: string]: boolean };
  /** Current [[User]]. */
  public user: User;

  constructor(
    private chatService: ChatService,
    private componentComm: ComponentCommService,
    private pusherService: PusherService,
    private loginService: LoginService,
    private companyService: CompanyService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    if (environment.modules.chat) {
      this.componentComm.emit({ name: 'app-title', title: 'MESSENGER_CENTER.TITLE' });

      this.subscriptions.push(this.loginService.getCurrentUser().subscribe(user => {
        this.user = user;

        if (this.user.accounts) this.subscriptions.push(this.companyService.watch().subscribe(company => {
          if (!company) return;

          this.company = company;
          this.loadRooms();

          // Escucho evento Pusher para novedades
          this.subscriptions.push(this.pusherService.listen(`company_${this.company.id}`, 'chat').subscribe(event => {
            if (event.data && event.data.room_id) {
              // Es un room en memoria?
              const roomIndex = this.rooms.findIndex(room => room.id === event.data.room_id);

              if (roomIndex !== -1) {
                // this.getRoom(event.data.room_id);
                if (event.data.message) {
                  this.rooms[roomIndex].messages = this.rooms[roomIndex].messages.filter(message => !message.sending);
                  this.rooms[roomIndex].messages.push(plainToInstance(ChatMessage, event.data.message));

                  if (roomIndex === this.selectedIndex) this.updateChatHeight();
                }
                if (event.data.context) {
                  this.rooms[roomIndex].context = event.data.context;
                }

                this.checkUnread();
              } else {
                // Refresco los rooms por si se trata de uno nuevo
                this.loadRooms();
              }
            }
          }));

          this.setupDrop(this.dropArea); // Initialize drag and drop
        }));
      }));
    } else this.router.navigateByUrl('/companies'); // Kick the user
  }

  private loadRooms(): void {
    this.subscriptions.push(this.chatService.get(this.company.id).subscribe(response => {
      this.rooms = response.body || [];
      this.checkUnread();
    }));
  }

  public setRoom(roomIndex: number): void {
    if (!this.processing) {
      if (this.selectedIndex !== roomIndex) {
        const room = this.rooms[roomIndex];

        if (!this.newMessage[room.id]) this.newMessage[room.id] = "";
        this.selectedIndex = roomIndex;

        if (room.messages?.length === 1) this.getRoom(roomIndex);
        else {
          this.updateChatHeight();
          this.setReaded();
        }
      } else {
        this.selectedIndex = undefined;
      }
    }
  }

  public submit(fileList?: FileList): void {
    this.processing = true;
    const roomId = this.rooms[this.selectedIndex].id;
    const fileArray = fileList ? Array.from(fileList) : undefined;

    this.send(roomId, fileArray);
  }

  private send(roomId: string, fileArray?: File[]): void {
    const phantomMessage: ChatMessage = {
      user: this.user,
      company: this.company,
      created_at: new Date(),
      sending: true
    };

    if (fileArray?.length > 0) {
      // Primero, debo enviar archivos
      const file = fileArray.shift();

      this.rooms[this.selectedIndex].messages.push({
        file: {
          name: file.name,
          type: file.type,
          size: file.size
        }, ...phantomMessage
      });

      this.subscriptions.push(this.chatService.uploadFile(this.company.id, roomId, file).subscribe(message => {
        this.send(roomId, fileArray);
      }));
    } else if (this.newMessage[roomId] && this.newMessage[roomId].trim() !== "") {
      this.rooms[this.selectedIndex].messages.push({ text: this.newMessage[roomId], ...phantomMessage });

      this.subscriptions.push(this.chatService.send(this.company.id, roomId, this.newMessage[roomId]).subscribe(message => {
        this.newMessage[roomId] = undefined;
        this.processing = false;
      }));
    } else {
      this.processing = false;
    }
    this.updateChatHeight();
  }

  private getRoom(roomIndex: number): void {
    this.processing = true;
    this.chatService.getRoom(this.company.id, this.rooms[roomIndex].id).subscribe(room => {
      this.rooms[roomIndex] = room; // actualizo
      this.processing = false;
      this.focus();
      this.updateChatHeight();
    });
  }

  private setReaded(): void {
    const room = this.rooms[this.selectedIndex];

    if (room.setReaded(this.user)) {
      this.chatService.context(this.company.id, room).subscribe(context => {
        this.rooms[this.selectedIndex].context = context;
      });
    }
  }

  private focus(delay: number = 0): void {
    setTimeout(() => {
      const input = document.getElementById('inputElement');
      if (input) input.focus();
    }, delay);
  }

  private updateChatHeight(): void {
    this.setReaded();
    setTimeout(() => {
      // Scroll pane down to the last message
      if (this.scrollMe) this.chatHeight = this.scrollMe.nativeElement.scrollHeight;
    });
  }

  private checkUnread(): void {
    this.unread = {};

    this.rooms.forEach(room => {
      if (room.hasUnread(this.user)) this.unread[room.id] = true;
    });
  }

  public changeFile(event): void {
    // Triggered by input component
    this.submit(event.target.files);
    event.target.value = ""; // Reset the input
  }

  private setupDrop(elSource: ElementRef): void {
    if (this.enableAttachments && elSource) {
      const el = elSource.nativeElement;

      el.addEventListener("dragenter", this.dragger, false);
      el.addEventListener("dragover", this.dragger, false);
      el.addEventListener("drop", this.drop, false);
      el.addEventListener("dragleave", this.removeDrag, false);
      el.addEventListener("dragend", this.removeDrag, false);
    }
  }

  private killDrop(elSource: ElementRef): void {
    if (elSource) {
      const el = elSource.nativeElement;

      // Removes all event listeners
      el.removeEventListener("dragenter", this.dragger);
      el.removeEventListener("dragover", this.dragger);
      el.removeEventListener("drop", this.drop);
      el.removeEventListener("dragleave", this.removeDrag);
      el.removeEventListener("dragend", this.removeDrag);
    }
  }

  private dragger = (e: Event) => {
    if (!this.processing && this.selectedIndex !== undefined) {
      e.stopPropagation();
      e.preventDefault();
      if (this.dropLegend) this.dropLegend.nativeElement.className = 'is-dragging';
    }
  };

  private drop = (e: DragEvent) => {
    if (!this.processing && this.selectedIndex !== undefined) {
      this.dragger(e);

      // Triggered by drop
      // Prevent to drag more files when the max files number is reached
      if (checkAccept(e.dataTransfer.files, this.accept)) this.submit(e.dataTransfer.files);
      // }
      this.removeDrag();
    }
  };

  private removeDrag = (e?: DragEvent) => {
    if (this.dropLegend) this.dropLegend.nativeElement.className = '';
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.killDrop(this.dropArea);

    // Delete the company from contextInfo
    // if (this.contextInfo && this.company && this.account) this.chatService.setPropertiesInContext(this.contextInfo.id, this.company.id, this.account.id, false);
    // Unsubscribe from everything
    // if (this.roomsSubscriptions) this.roomsSubscriptions.unsubscribe();
    super.ngOnDestroy();
  }
}
