import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable, Observer, map } from 'rxjs';

import { FileManagerFile } from '../../models/file-manager-file.model';
import { JSend } from '../../models/jsend.model';
import { buildFilters } from '../../utilities/filters';
import { ChatFile, ChatMessage } from '../models/chat-message.model';
import { ChatRoom } from '../models/chat-room.model';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private baseUrl: string = '/:apiChat/companies/:companyId/rooms';
  private roomUrl: string = this.baseUrl + '/:roomId';
  private contextUrl: string = this.roomUrl + '/context';
  private fileUrl: string = this.roomUrl + '/file';
  private fileByIdUrl: string = this.fileUrl + '/:fileId';

  /** Maps only those parameters that don't match in the API call. */
  private readonly queryMap: Record<string, string>;

  constructor(
    private http: HttpClient
  ) { }

  public create(companyId: number, room: ChatRoom): Observable<ChatRoom> {
    let url = this.baseUrl
      .replace(":companyId", companyId.toString());

    const stream = this.http.post<JSend<{ room: ChatRoom }>>(url, room);

    return stream.pipe(map(response => {
      return plainToInstance(ChatRoom, response.data.room);
    }));
  }

  public get(companyId: number, filters?: any, paginated: boolean = true): Observable<{ body: ChatRoom[], headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    let url = this.baseUrl
      .replace(":companyId", companyId.toString());

    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<JSend<{ rooms: ChatRoom[] }>>(url, { observe: 'response' });

    return stream.pipe(map(response => {
      if (response.body.status === 'success') return { body: plainToInstance(ChatRoom, response.body.data.rooms), headers: response.headers };
      else throw new Error(String(response.body.data));
    }));
  }

  public getRoom(companyId: number, roomId: string): Observable<ChatRoom> {
    let url = this.roomUrl
      .replace(":companyId", companyId.toString())
      .replace(":roomId", roomId);

    const stream = this.http.get<JSend<{ room: ChatRoom }>>(url);

    return stream.pipe(map(response => {
      if (response.data.room.messages) response.data.room.messages.reverse();
      return plainToInstance(ChatRoom, response.data.room);
    }));
  }

  public send(companyId: number, roomId: string, text?: string, file?: ChatFile): Observable<ChatMessage> {
    let url = this.roomUrl
      .replace(":companyId", companyId.toString())
      .replace(":roomId", roomId);

    let data: {
      text?: string;
      file?: ChatFile;
    } = {};

    if (text) data.text = text.trim();
    if (file) data.file = file;

    const stream = this.http.post<JSend<{ message: ChatMessage }>>(url, data);

    return stream.pipe(map(response => {
      return plainToInstance(ChatMessage, response.data.message);
    }));
  }

  public context(companyId: number, room: ChatRoom): Observable<any> {
    let url = this.contextUrl
      .replace(":companyId", companyId.toString())
      .replace(":roomId", room.id);

    const stream = this.http.put<JSend<{ context: any }>>(url, room.context);

    return stream.pipe(map(response => {
      return response.data.context;
    }));
  }

  public uploadFile(companyId: number, roomId: string, file: File): Observable<ChatMessage> {
    return new Observable((observer: Observer<ChatMessage>) => {
      let url = this.fileUrl
        .replace(":companyId", companyId.toString())
        .replace(":roomId", roomId);

      this.http.get<JSend<{
        file: {
          id: string;
          url: string;
        }
      }>>(url).subscribe(response => {
        // Obtengo ID y URL para enviar
        const fileData = response.data.file;

        this.http.put(fileData.url, file).subscribe(uploaded => {
          // Archivo subido
          this.send(companyId, roomId, undefined, {
            id: fileData.id,
            name: file.name,
            type: file.type,
            size: file.size
          }).subscribe(message => {
            // Mensaje posteado
            observer.next(message);
          });
        })
      });
    });
  }

  public file(companyId: number, roomId: string, fileId: string): Observable<FileManagerFile> {
    let url = this.fileByIdUrl
      .replace(":companyId", companyId.toString())
      .replace(":roomId", roomId)
      .replace(":fileId", fileId);

    const stream = this.http.get<FileManagerFile>(url);

    return stream.pipe(map(response => plainToInstance(FileManagerFile, response)));
  }
}
